<nav class="navbar sticky-top navbar-dark navbar-expand-lg fluid">
  <a class="nav navbar-nav navbar-left navbar-brand" routerLink="">
    <img src="../assets/logo/logo_RJ.png" alt="" width="42" height="42" title="Home" loading="lazy">
  </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    
    <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
      <div class="navbar-nav mx-auto justify-content-center align-items-center">
        <a class="nav-item nav-link" routerLink="/about" id="aboutNavbar">About</a>
        <a class="nav-item nav-link" routerLink="/work-experience" id="weNavbar">Work Experience</a>
        <a class="nav-item nav-link" routerLink="/projects" id="projectsNavbar">Projects</a>
        <a class="nav-item nav-link" routerLink="/extracurricular" id="extraNavbar">Extracurricular Activities</a>
      </div>
    </div>
      <a tabindex="0" class="btn btn-primary" role="button" data-toggle="popover" data-trigger="hover focus" data-placement="bottom" data-html="true"
      data-content="<em>Email</em>: jayasimha.3@osu.edu">
        Contact
    </a>
</nav>