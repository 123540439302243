<div class="container-xl" flex>
    <div class="card mb-3" style="max-width: 100%;">
        <div class="card-header"><h5 class="mt-0 text-uppercase font-weight-bold">Work Experience</h5></div>
            <div class="card-body text-dark">
                <div class="media">
                    <img src="/assets/gm/gm.jpg" class="mr-3" alt="General Motors">
                        <div class="media-body">
                            <button type="button" class="btn btn-primary float-right btn-sm">
                                Duration <span class="badge badge-light"> January 2021 - Present </span>
                            </button>
                            <h6 class="text-break">
                                Software Developer (Entry Level)
                            </h6>
                            <h6 class="font-italic">General Motors Global Technical Center, Warren MI</h6>
                        </div>
                </div>
            </div>
            <div class="card-footer">
                <a href="./work-experience" class="card-link">More Work Experience</a>
            </div>
    </div>
    <div class="card mb-3" style="max-width: 100%;">
        <div class="card-header"><h5 class="mt-0 text-uppercase font-weight-bold">Education</h5></div>
            <div class="card-body text-dark">
                <div class="media">
                    <img src="/assets/ohiostate/blockO.png" class="mr-3" alt="The Ohio State University">
                        <div class="media-body">
                            <button type="button" class="btn btn-primary float-right btn-sm">
                                Graduated <span class="badge badge-light"> May 3, 2020 </span>
                            </button>
                            <h6 class="text-break">
                                B.S. Computer Science and Engineering
                            </h6>
                            <h6 class="font-italic">The Ohio State University, Columbus OH</h6>
                            <h6 class="font-weight-lighter text-break">
                                Specialization: Artificial Intelligence, 4x Dean’s List Award
                            </h6>
                            <h6 class="font-weight-lighter text-break">
                                GPA: 3.001
                            </h6>
                        </div>
                </div>
            </div>
    </div>

            <div class="card d-flex-row">
                <div class="card-body">
                    <h5 class="card-title">SKILLS</h5>
                    <h6 class="card-subtitle mb-2 text-muted">These are a list of programming languages and software I have learned thorugh projects and coursework.</h6>
                    <p><span class="badge badge-primary">Primary Programming Languages</span><span class="badge badge-dark">Other Languages and Software</span></p>
                    <p class="card-text">
                        <div class="btn-group mr-2 text-wrap d-flex flex-wrap" style="max-width: 100%" role="group" aria-label="Primary Languages">
                            <button type="button" class="btn btn-primary">Java</button>
                            <button type="button" class="btn btn-primary">C</button>
                            <button type="button" class="btn btn-primary">C#</button>
                            <button type="button" class="btn btn-primary">SQL</button>
                            <button type="button" class="btn btn-primary">Python</button>
                            <button type="button" class="btn btn-primary">JavaScript</button>
                            <button type="button" class="btn btn-primary">Ruby</button>
                        </div>
                        <div class="btn-group mr-2 btn-group-sm text-wrap d-flex flex-wrap" role="group" aria-label="Secondary Languages and Other Software">
                            <button type="button" class="btn btn-dark">Arduino</button>
                            <button type="button" class="btn btn-dark">MATLAB</button>
                            <button type="button" class="btn btn-dark">HTML</button>
                            <button type="button" class="btn btn-dark">CSS</button>
                            <button type="button" class="btn btn-dark">Bootstrap</button>
                            <button type="button" class="btn btn-dark">Linux</button>
                            <button type="button" class="btn btn-dark">Angular</button>
                            <button type="button" class="btn btn-dark">Autodesk Inventor</button>
                            <button type="button" class="btn btn-dark">SOLIDWORKS</button>
                        </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">COURSES</h5>
                    <h6 class="card-subtitle mb-2 text-muted">There are several courses that are required to complete when earning a CSE degree, and others that are required,
                         but chosen from many options. These choice-required courses are listed below, along with other, high-level non-CSE, courses I took.
                    </h6>
                    <p>
                         <span class="badge badge-primary">AI Specialization Required</span><span class="badge badge-dark">CSE</span><span class="badge badge-success">Other</span>
                    </p>
                    <p class="card-text">
                        <div class="btn-group mr-2 btn-group-sm text-wrap d-flex flex-wrap" role="group" aria-label="CSE Courses 0">
                            <button type="button" class="btn btn-primary">Artificial Intelligence 1/2</button>
                            <button type="button" class="btn btn-primary">Machine Learning</button>
                            <button type="button" class="btn btn-primary">AI Capstone</button>
                        </div>
                        <div class="btn-group mr-2 btn-group-sm text-wrap d-flex flex-wrap" role="group" aria-label="CSE Courses 1">
                            <button type="button" class="btn btn-dark">Database Systems</button>
                            <button type="button" class="btn btn-dark">Computer Architecture</button>
                            <button type="button" class="btn btn-dark">Automata and Formal Languages</button>
                            <button type="button" class="btn btn-dark">Game and Animation Techniques</button>
                            <button type="button" class="btn btn-dark">Project: Web Applications</button>
                        </div>
                        <div class="btn-group mr-2 btn-group-sm text-wrap d-flex flex-wrap" role="group" aria-label="Other Courses">
                            <button type="button" class="btn btn-success">Aerospace Engineering 1</button>
                            <button type="button" class="btn btn-success">Statics and Mechanics of Materials</button>
                            <button type="button" class="btn btn-success">Digital Logic</button>
                            <button type="button" class="btn btn-success">Analog Systems and Circuits</button>
                        </div>
                </div>
            </div> 
    
</div>