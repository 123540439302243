<!-- Footer -->
<footer class="navbar navbar-dark bg-dark navbar-expand-lg sticky-bottom">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <a class="navbar-brand" href="https://github.com/jayasimha3">
          <img src="/icons/github_dark.svg" alt="" width="32" height="32" title="Github">
        </a>
        <a class="navbar-brand" href="https://www.linkedin.com/in/jayasimha3/">
          <img src="/icons/linkedin.svg" alt="" width="32" height="32" title="LinkedIn">
        </a>
        <a class="navbar-brand" href="https://osu.joinhandshake.com/users/11578467">
          <img src="/icons/handshake.svg" alt="" width="32" height="32" title="Handshake">
        </a>
        <a class="navbar-brand" href="https://my.indeed.com/p/risheej-w1nbb5b">
          <img src="/icons/indeed.svg" alt="" width="64" height="32" title="Indeed">
        </a>
      </ul>
      <ul class="container d-flex navbar-nav mx-auto justify-content-center align-items-center" data-toggle="tooltip" data-placement="top" title="The switch does not work. The site is permanently dark mode in this version.">
        <div class="custom-control custom-switch">
          <input type="checkbox" class="custom-control-input" id="darkSwitch" />
          <label class="custom-control-label" for="darkSwitch">Dark Mode</label>
        </div>
      </ul>
        <span class="navbar-text">
          Rishee Jayasimha | 
          <a class="text-monospace text-muted" href="https://github.com/jayasimha3/jayasimha3.github.io/tree/master/assets/CHANGELOG.md">Version 2.0.1</a>
        </span>
    </div>
  </footer>

